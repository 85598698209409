import React from 'react';
import PDF from "../../assets/images/popupMenu2.0.png"
const PdfViewer = () => {
  return (
    <div>
      <img src={PDF} className='h-auto' />
    </div>
  );
};

export default PdfViewer;
